<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
// import DevicePixelRatio  from './common/DevicePixelRatio'
export default {
  data() {
    return {}
  },
  created() {
    // new DevicePixelRatio().init();//适配放大缩小
  }
}
</script>
