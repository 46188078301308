import Vue from 'vue'
import App from './App.vue'
import axios from "axios";
import router from './router'
import store from './store'

Vue.config.productionTip = false

// 引入全局css
import './styles/style.scss'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

// 引入echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';
Vue.component('icon', Icon);

Vue.prototype.$axios = axios;

axios.interceptors.response.use(
  response => {
    const res = response.data;
    if (res.RetCode == '10000') {
      return res;
    } else {
      return Promise.reject(new Error(res.RetMsg || 'Error'));
      // return res;
    }
  },
  error => {
    return Promise.reject(error);
  }
)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
